@import url("src/app/modules/role/component/listRole.css");
.error {
  color: red !important;
}
.card-animation {
  opacity: 1 !important;
  transition: opacity 0.3s ease-in-out !important;
}

.card-animation-hidden {
  opacity: 0 !important;
  transition: opacity 0.3s ease-in-out !important;
  pointer-events: none !important;
}
.Toastify__toast {
  font-family: "Poppins" !important;
}
.swal-text {
  color: black !important;
}
.swal-title {
  color: black;
}

/* Custom styles for plan listing */
.plan-listing {
  margin-top: 20px;
}

.plan-card {
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3) !important;
}

.plan-title {
  font-weight: bold;
  font-size: 1rem;
}

.plan-price {
  font-size: 1rem;
  color: #007bff;
}

.plan-description {
  margin-top: 10px;
  color: #6c757d;
}

/* Add Bootstrap classes for button styling */

/* Add Bootstrap classes for button hover effect */
.plan-button button:hover {
  background-color: #007bff;
}

/* Add Bootstrap classes for button hover text color */
.plan-button button:hover span {
  color: #fff;
}
.selected {
  border: 2px solid #007bff; /* Replace with your desired border color */
}

.bg-stripe {
  background-image: linear-gradient(
    to right bottom,
    #b996a9,
    #b699b2,
    #b19db9,
    #aca2c0,
    #a5a6c6,
    #a1adce,
    #9cb5d4,
    #98bcd9,
    #96c8e0,
    #96d4e5,
    #9ae0e7,
    #a3ebe6
  );
}

.selected-plan-details {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}

.plan-detail-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.plan-detail-list {
  display: flex;
  flex-direction: column;
}

.plan-detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.plan-detail-label {
  font-weight: bold;
  width: 200px;
  margin-right: 10px;
}

.plan-detail-value {
  flex: 1;
}

.invoice-container {
  margin: 15px auto;
  padding: 70px;
  max-width: 850px;
  background-color: #fff;
  /* border: 1px solid #ccc;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px; */
}
.notVisibleInDesktop {
  display: none;
}

@media (max-width: 895px) {
  .invoice-container {
    margin: 15px;
  }
}
@media (max-width: 767px) {
  .invoice-container {
    padding: 45px 45px 70px 45px;
  }
}

@media (max-width: 499px) {
  .invoice-header {
    text-align: center;
  }
}

.invoice-col {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 500px) {
  .invoice-col {
    float: left;
    width: 50%;
  }
  .invoice-col.right {
    float: right;
    text-align: right;
  }
}

/* Invoice Status Formatting */

.invoice-container .invoice-status {
  margin: 20px 0 0 0;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
}

/* Invoice Status Colors */

.draft {
  color: #888;
}
.unpaid {
  color: #cc0000;
}
.paid {
  color: #779500;
}
.refunded {
  color: #224488;
}
.cancelled {
  color: #888;
}
.collections {
  color: #ffcc00;
}

/* Payment Button Formatting */

.invoice-container .payment-btn-container {
  margin-top: 5px;
  text-align: center;
}
.invoice-container .payment-btn-container table {
  margin: 0 auto;
}

/* Text Formatting */

.invoice-container .small-text {
  font-size: 0.9em;
}

/* Invoice Items Table Formatting */

.invoice-container td.total-row {
  background-color: #f8f8f8;
}
.invoice-container td.no-line {
  border: 0;
}
.invoice-container .invoice-header img {
  max-width: 100%;
}

/* Overlay */

#fullpage-overlay {
  display: table;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
#fullpage-overlay .outer-wrapper {
  position: relative;
  height: 100%;
}
#fullpage-overlay .inner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  width: 50%;
  margin: -3% 0 0 -25%;
  text-align: center;
}
#fullpage-overlay .msg {
  display: inline-block;
  padding: 20px;
  max-width: 400px;
}
/* table responseive */
.mobilewidth {
  width: 25px;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.selectAll {
  display: none;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 576px) {
  .formedia {
    width: min-content;
  }
  .table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block !important;
  }
  body {
    font-size: 10px !important;
  }
  .notVisibleInMobile {
    display: none !important;
  }
  .notVisibleInDesktop {
    display: block !important;
  }
  .floatRight {
    justify-content: end;
  }
  .selectOPtion {
    display: inline !important;
  }
  .alignSelect {
    margin-left: 22rem !important;
  }
  .alignSelect:checked {
    margin-left: 26rem !important;
  }
  .mobileViewalign {
    display: flex;
    justify-content: space-between;
  }
  .selectAll {
    display: inline;
  }
  .mobilewidth {
    width: 100% !important;
  }

  tr {
    margin-bottom: 10px !important;
  }

  td {
    display: block !important;
    text-align: right !important;
    position: relative !important;
    padding-left: 50% !important;
    border: none !important;
    border-bottom: 1px solid #eee !important;
  }

  td:before {
    content: attr(data-label) !important;
    position: absolute !important;
    left: 0 !important;
    width: 50% !important;
    padding-left: 10px !important;
    font-weight: bold !important;
    text-align: left !important;
  }
}
/* th {
  word-wrap: break-word !important; 
  white-space: normal !important; 
} */
.activePlan {
  color: #ffffff;
  border-color: #009ef7;
  background-color: #009ef7 !important;
}

/* aside
.svg-icon-2 {
  color: #ffffff; 
  transition: color 0.3s ease;
}

.svg-icon-2:hover {
  color: #5b0dd8 !important;
}
*/
/* .rs-picker-daterange-panel {
  z-index: 100000;
} */
/* rs-anim-fade rs-anim-in rs-picker-popup-daterange rs-picker-popup placement-right */
.rs-anim-fade {
  z-index: 100000;
}
