.iconstyle {
  font-size: 2rem;
}
.iconstyle:hover {
  color: #009ef7;
}
.bi-trash:hover {
  color: #ff0707;
}
.form {
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 0.475rem;
  outline: #5e6278;
}
.outline {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
