/* Loader styles */
.spinnerround {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #5e6278(255, 255, 255, 0.7); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000000;
}

/* Simple spinner animation */
.spinner {
  border: 8px solid #009ef7;
  border-radius: 50%;
  border-top: 8px solid #d4dbe0;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Class to blur the background when the loader is active */
.blurred-content {
  filter: blur(1px); /* Apply the blur effect to the background */
  pointer-events: none; /* Disable interactions with blurred content */
}
